import React, { useEffect, useState } from "react";
import "../../Styles/Notification.css";

function Notification({ message, duration = 5000, onClose }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
        onClose && onClose();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [message, duration, onClose]);

  if (!visible) return null;

  return (
    <div className="notification">
      <div className="notification-content">
        <p>{message}</p>
        <button
          onClick={() => {
            setVisible(false);
            onClose && onClose();
          }}
        >
          Закрыть
        </button>
      </div>
    </div>
  );
}

export default Notification;
