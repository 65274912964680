// @ts-nocheck
import { useState, useCallback, useEffect, useContext } from "react";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import axios from "axios";
import "../Styles/ChessBoard.css";
import Modal from "./Modals/Modals";
import { useParams, useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";
import * as api from "../Utils/API/API";
import { setStatistic } from "../Utils/API/statistic";
import { SolvedPuzzle } from "../Utils/API/puzzle";
import { HomeworkComplite } from "../Utils/API/Homework";
import { Snackbar, Alert } from "@mui/material";

function HomeworkPuzzles() {
  const { user, setUser } = useContext(UserContext); // Информация о юзере
  const [puzzle, setPuzzle] = useState("");
  const [currentMoveIndex, setCurrentMoveIndex] = useState(0);
  const [allMoveInfo, setMoveInfo] = useState({
    move: "",
    currentIndex: 0,
    isRigth: "",
  });

  const [fen, setFen] = useState("");
  const [solution, setSolution] = useState([]); // Инициализируем как массив
  const [chess, setChess] = useState(new Chess()); // Инициализируем Chess.js
  const [orientation, setOrientation] = useState("white");
  const [isLoading, setIsLoading] = useState(true); // Состояние загрузки
  const [timeStart, setTime] = useState(new Date());
  const [timeStartPuzzle, setTimePuzzle] = useState(new Date()); // Полное время решения задачи
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [notification, setNotification] = useState<{
    message: string;
    severity: "success" | "error";
  } | null>(null);

  let { puzzleId, homework, owner } = useParams();

  // Новые состояния для обработки кликов
  const [selectedSquare, setSelectedSquare] = useState(null);
  const [possibleMoves, setPossibleMoves] = useState({});

  const makeAMove = useCallback(
    (move) => {
      try {
        const result = chess.move(move);
        if (result) {
          setFen(chess.fen());
        }
        return result;
      } catch (error) {
        return null; // Возвращаем null, если ход был недействительным
      }
    },
    [chess]
  );

  useEffect(() => {
    // Сброс состояния при загрузке нового пазла
    setCurrentMoveIndex(0);
    setMoveInfo({
      move: "",
      currentIndex: 0,
      isRigth: "",
    });
    setIsLoading(true);
    setSelectedSquare(null);
    setPossibleMoves({});

    // Загрузка данных пазла
    axios
      .get(
        `${api.protocol}://${api.backendHost}/api/homework/${owner}/${homework}/${puzzleId}`,
        {
          withCredentials: true,
        }
      )
      .then((resp) => {
        if (resp.data.success) {
          console.log("Входные данные", owner, homework, puzzleId);
          console.log("Информация о пазле", resp);
          setPuzzle(resp.data);
          setSolution(resp.data.solution); // Прямо устанавливаем массив
          const parts = resp.data.fen.split(" ");
          const player = parts[1]; // Получаем текущего игрока (w или b)
          setOrientation(player === "w" ? "black" : "white");
          setFen(resp.data.fen); // Устанавливаем начальную позицию
          setIsLoading(false);
          setTime(new Date());
          setTimePuzzle(new Date());
        } else {
          alert("В домашнем задании нет задач");
        }
      })
      .catch((error) => {
        console.error("Ошибка при загрузке пазла:", error);
      });
  }, [puzzleId, owner, homework]);

  useEffect(() => {
    if (puzzle && !isLoading) {
      const newChess = new Chess(puzzle.fen);
      setChess(newChess);
      setFen(newChess.fen());
      if (solution.length > 0) {
        newChess.move(solution[0]);
        setFen(newChess.fen());
        setCurrentMoveIndex((a) => a + 1);
      }
      // Не применяем никакие ходы из solution при загрузке
    }
  }, [isLoading, puzzle, solution]);

  async function puzzleSolved() {
    setModalOpen(true);
    const solvedPuzzle = {
      Puzzleid: puzzle.puzzleid,
      Studentid: user.id,
      TimeValue: new Date() - timeStartPuzzle,
    };
    console.log("Информация об отправляемом решении правильном", solvedPuzzle);
    var response = await SolvedPuzzle(solvedPuzzle);
    const differenceInMillis = new Date() - timeStart;
    const differenceInSeconds = Math.floor(differenceInMillis / 1000);
    const minutes = Math.floor(differenceInSeconds / 60);
    const seconds = differenceInSeconds % 60;
    var response = await HomeworkComplite(owner, homework, user.id);
    console.log(
      `Разница: ${minutes} минут(ы) и ${seconds} секунд(ы)`,
      response
    );
  }

  function opponentMove() {
    const nextMove = solution[currentMoveIndex + 1];
    chess.move(nextMove);
    setFen(chess.fen());
    setCurrentMoveIndex((a) => a + 1);
  }

  useEffect(() => {
    if (allMoveInfo.move && user.role !== "anonimous") {
      setStatistic(
        user,
        puzzle.puzzleid,
        allMoveInfo,
        timeStart,
        allMoveInfo.isRigth
      );
      console.log("Правильность хода", allMoveInfo.isRigth);
      setTime(new Date());
    }
  }, [allMoveInfo]);

  // Обработчик drag-and-drop ходов
  async function onDrop(sourceSquare, targetSquare) {
    const moveData = {
      from: sourceSquare,
      to: targetSquare,
      promotion: "q", // Вы можете добавить логику для выбора фигуры при превращении
    };

    const move = makeAMove(moveData);

    if (move != null) {
      // Сравниваем 'from + to' с текущим ходом в решении
      if (move.lan === solution[currentMoveIndex]) {
        setMoveInfo({
          move: move,
          currentIndex: currentMoveIndex + 1,
          isRigth: true,
        });
        setCurrentMoveIndex((a) => a + 1); // Переход к следующему ходу
        // Если еще есть ходы в решении, выполняем следующий ход
        if (currentMoveIndex < solution.length - 1) {
          opponentMove();
        } else {
          await puzzleSolved();
        }
        return true; // Ход был правильным
      } else {
        setMoveInfo({
          move: move,
          isRigth: false,
        });
        // Возврат к предыдущей позиции
        chess.undo();
        setFen(chess.fen());
        setNotification({
          message: "Неправильный ход",
          severity: "error",
        });
        // alert("Неверный ход!");
        return false; // Ход был неверным
      }
    } else {
      return false;
    }
  }

  // Новый обработчик кликов по клеткам
  async function onSquareClick(square) {
    // Если уже выбрана фигура, пытаемся сделать ход
    if (selectedSquare) {
      if (selectedSquare === square) {
        // Если кликнули на ту же клетку, сбрасываем выбор
        setSelectedSquare(null);
        setPossibleMoves({});
        return;
      }

      const move = {
        from: selectedSquare,
        to: square,
        promotion: "q", // Вы можете добавить логику для выбора фигуры при превращении
      };

      const result = makeAMove(move);
      if (result) {
        // Сравниваем 'from + to' с текущим ходом в решении
        const moveString = result.from + result.to;
        console.log("movestr solution", moveString, currentMoveIndex, move.lan);
        if (result.lan === solution[currentMoveIndex]) {
          setMoveInfo({
            move: result,
            currentIndex: currentMoveIndex + 1,
            isRigth: true,
          });
          setCurrentMoveIndex(currentMoveIndex + 1); // Переход к следующему ходу
          // Если еще есть ходы в решении, выполняем следующий ход
          if (currentMoveIndex < solution.length - 1) {
            opponentMove();
          } else {
            await puzzleSolved();
          }
        } else {
          setMoveInfo({
            move: result,
            isRigth: false,
          });
          // Возврат к предыдущей позиции
          chess.undo();
          setFen(chess.fen());
          setNotification({
            message: "Неправильный ход",
            severity: "error",
          });
          // Уведомление об ошибке
          // alert("Неверный ход!");
        }
        setSelectedSquare(null);
        setPossibleMoves({});
      } else {
        // Если ход недействительный, сбрасываем выбор
        setSelectedSquare(null);
        setPossibleMoves({});
      }
    } else {
      // Выбираем фигуру и показываем возможные ходы
      const piece = chess.get(square);
      if (piece && piece.color === chess.turn()) {
        setSelectedSquare(square);
        const moves = chess.moves({ square, verbose: true });
        const newPossibleMoves = {};
        moves.forEach((m) => {
          newPossibleMoves[m.to] = {
            background: m.flags.includes("capture")
              ? "radial-gradient(circle, rgba(255, 0, 0, 0.6) 0%, rgba(255, 0, 0, 0.6) 15%, transparent 16%)"
              : "radial-gradient(circle, rgba(0, 0, 255, 0.6) 0%, rgba(0, 0, 255, 0.6) 15%, transparent 16%)",
            borderRadius: "50%",
            boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)", // Легкая тень для глубины
          };
        });
        setPossibleMoves(newPossibleMoves);
      }
    }
  }

  const handleNextTask = async () => {
    try {
      const response = await axios.get(
        `${api.protocol}://${api.backendHost}/api/homework/next/${owner}/${homework}/${puzzle.puzzleOrder}`
      );
      navigate(`/homework/${owner}/${homework}/${response.data}`);
    } catch (error) {
      console.error("Ошибка", error);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleShowModal = () => {
    setModalOpen(true);
  };

  const handleAddTask = (theme) => {
    console.log(`Задача добавлена в тему: ${theme}`);
    // Логика для добавления задачи в выбранную тему
  };

  // Подготовка стилей для подсветки клеток
  const customSquareStyles = selectedSquare
    ? {
        [selectedSquare]: { backgroundColor: "rgba(255, 255, 0, 0.4)" },
        ...possibleMoves,
      }
    : possibleMoves;

  // Компонент возвращает JSX
  return (
    <>
      <div className="parent">
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          handleNextTask={handleNextTask}
        />
        <div className="board">
          <>Ход {orientation === "white" ? "Белых" : "Черных"}</>
          <Chessboard
            position={fen}
            onPieceDrop={onDrop} // Поддержка drag-and-drop
            boardOrientation={orientation}
            onSquareClick={onSquareClick} // Обработчик кликов
            customSquareStyles={customSquareStyles} // Стиль подсветки
            // Можно добавить другие пропсы по необходимости
          />
        </div>
      </div>
      <div className="task-container">
        <div className="task">
          Текущая задача {puzzle.puzzleCount}, рейтинг задачи {puzzle.rating}
        </div>
        <div className="task">Текущая тема {homework}</div>
        {/* Кнопка для перехода к следующей задаче */}
        <button className="next-task-button" onClick={handleNextTask}>
          Следующая задача
        </button>
      </div>
      {notification && (
        <Snackbar
          open={Boolean(notification)}
          autoHideDuration={6000}
          onClose={() => setNotification(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => setNotification(null)}
            severity={notification.severity}
            sx={{ width: "100%" }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default HomeworkPuzzles;
