// @ts-nocheck
import React from "react";
import "../../Styles/Modals.css"; // Импортируйте стиль для модального окна

const Modal = ({ isOpen, onClose, handleNextTask }) => {
  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Задача решена верно!</h2>
        <button className="next-task-button" onClick={onClose}>
          Закрыть
        </button>
        <button
          className="next-task-button"
          onClick={(e) => {
            handleNextTask();
            onClose();
          }}
        >
          {" "}
          Следующая
        </button>
      </div>
    </div>
  );
};

export default Modal;
