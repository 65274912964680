import axios from "axios";
import * as api from "./API";

export const AddTrainee = async (coachid: number, studentName: string) => {
  try {
    var response = await axios.get(
      `${api.protocol}://${api.backendHost}/api/coach/add?coachid=${coachid}&studentLichessName=${studentName}`
    );
    return response;
  } catch {
    var responseError = {
      status: 500,
      data: {
        message: "Ошибка при добавлении ученика",
      },
    };
    // Возвращаем собственный объект с информацией об ошибке
    return responseError;
  }
};

export const AllTrainee = async () => {
  try {
    var response = await axios.get(
      `${api.protocol}://${api.backendHost}/api/coach/alltrainee`,
      {
        withCredentials: true, // Разрешаем отправку куки с запросом
      }
    );
    return response;
  } catch {
    var responseError = {
      status: 500,
      data: {
        message: "Ошибка при нахождении учеников",
      },
    };
    // Возвращаем собственный объект с информацией об ошибке
    return responseError;
  }
};

export const addCoachByToken = async (token: any) => {
  try {
    const response = await axios.get(
      `${api.protocol}://${api.backendHost}/api/coachtoken/${token}`,
      {
        withCredentials: true, // Разрешаем отправку куки с запросом
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const CoachLink = async (token: any) => {
  try {
    const response = await axios.get(
      `${api.protocol}://${api.backendHost}/api/coach/coachlink`,
      {
        withCredentials: true, // Разрешаем отправку куки с запросом
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
