// @ts-nocheck
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "../Styles/HomePage.css";
import { UserContext } from "./UserContext";

const HomePage = () => {
  const { user, setUser } = useContext(UserContext);

  return (
    <div className="container">
      <h1 className="header">
        Добро пожаловать в шахматное приложение!
        {user.isLogged ? (
          <> {/* Привет, {user.id}, {user.role}{" "} */}</>
        ) : (
          <> Войдите, чтобы получить больше возможностей </>
        )}
      </h1>

      {/* Карточки функций */}
      <div className="card-container">
        <div className="card">
          <h2>Анализ партий</h2>
          <p>Создайте комнату и анализируйте ваши успехи.</p>
        </div>
        <div className="card">
          <h2>Задачи</h2>
          <p>Тренируйтесь, решая шахматные задачи.</p>
        </div>
        <div className="card">
          <h2>Профиль</h2>
          <p>Просмотрите свой прогресс и настройки профиля.</p>
        </div>
        <div className="card">
          <h2>История задач</h2>
          <p>Посмотрите ваши успехи в предыдущих задачах.</p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
