// @ts-nocheck
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../Styles/ChessThemes.css";
import { themes, lichessTheme } from "../Utils/Themes";
import HomeworkTopics from "./HomeworkTopics";

const Tactic = () => {
  const [activeTheme, setActiveTheme] = useState(null); // Текущая тема с открытым списком рейтингов

  // Диапазоны рейтинга
  const ratingRanges = [
    { label: "500-1000", min: 500, max: 1000 },
    { label: "1000-1500", min: 1000, max: 1500 },
    { label: "1500-2000", min: 1500, max: 2000 },
    { label: "2000-2500", min: 2000, max: 2500 },
    { label: "2500-3000", min: 2500, max: 3000 },
    { label: "3000+", min: 3000, max: 9999 },
  ];
  // Функция для отображения или скрытия выпадающего списка для выбранной темы
  const toggleDropdown = (theme) => {
    setActiveTheme(activeTheme === theme ? null : theme); // Открыть меню для выбранной темы или закрыть, если нажали повторно
  };
  return (
    <div>
      <h1 align="center">Темы шахматных задач</h1>
      <h2 align="center">
        Здесь вы можете выбрать задачи для своих домашних заданий
      </h2>
      {/* Список тем с карточками */}
      <div className="themes-container">
        {themes.map((theme, index) => (
          <div key={index} className="theme-wrapper">
            <div onClick={() => toggleDropdown(theme)} className="theme-card">
              {theme}
            </div>

            {/* Выпадающий список с диапазонами рейтинга для выбранной темы */}
            {activeTheme === theme && (
              <div className="dropdown-menu">
                {ratingRanges.map((range, i) => (
                  <Link
                    // to={`/puzzle/${lichessTheme[theme]}/${range.range}/1`}
                    to={`/puzzle/${lichessTheme[theme]}/${1}/${range.min}/${
                      range.max
                    }`}
                    key={i}
                    className="dropdown-item"
                  >
                    {range.label}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <HomeworkTopics />
    </div>
  );
};

export default Tactic;
