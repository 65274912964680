import axios from "axios";
import * as api from "./API";
import Cookies from "js-cookie";

let GetUserId = async () => {
  var response = await axios.get(
    `${api.protocol}://${api.backendHost}/api/myid`,
    {
      withCredentials: true,
    }
  );
  return response.data;
};

let GetUserInfo = async () => {
  var response = await axios.get(
    `${api.protocol}://${api.backendHost}/api/profile`,
    {
      withCredentials: true,
    }
  );
  return response.data;
};

export let GetUserInfoById = async (id) => {
  var response = await axios.get(
    `${api.protocol}://${api.backendHost}/api/profile/${id}`,
    {
      withCredentials: true,
    }
  );
  return response.data;
};

export { GetUserId, GetUserInfo };
