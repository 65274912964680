// @ts-nocheck

import { useState, useEffect, useContext } from "react";
import { UserContext } from "../UserContext";
import "../../Styles/SydeBar.css"; // Подключим стили для бокового модального окна
import { GetHomework } from "../../Utils/API/Homework";
import { AddPuzzleToHomework } from "../../Utils/API/Homework";

function CoachSideBar({ puzzleId }) {
  const { user } = useContext(UserContext);
  const [selectedTheme, setSelectedTheme] = useState("");
  const [homeworks, setThemes] = useState([]);
  const [addedHomeworks, setAddedHomeworks] = useState<{
    [key: number]: boolean;
  }>({}); // Объект для отслеживания статуса

  const handleClick = async (homeworkId: number) => {
    try {
      await AddPuzzleToHomework(homeworkId, puzzleId);

      setAddedHomeworks((prevState) => ({
        ...prevState,
        [homeworkId]: true, // Меняем статус для конкретной домашней работы
      }));
    } catch (error) {
      console.error("Ошибка при добавлении головоломки", error);
    }
  };
  // Модальное окно будет отображаться только для тренеров
  useEffect(() => {
    const GetThemes = async () => {
      var themes = await GetHomework();
      setThemes(themes.data);
    };
    GetThemes();
  }, []);

  useEffect(() => {
    setAddedHomeworks({});
  }, [puzzleId]); // Следим за изменениями puzzleId

  if (user.role !== "coach") {
    return null;
  }

  return (
    <div className="sidebar-modal">
      <table className="homework-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Тема</th>
            <th>Добавить задачу в тему</th> {/* Новый заголовок для кнопки */}
          </tr>
        </thead>
        <tbody>
          {homeworks.length > 0 ? (
            homeworks.map((homework) => (
              <tr key={homework.homeworkid}>
                <td>{homework.homeworkid}</td>
                <td>{homework.theme}</td>
                <td>
                  <button
                    className="next-task-button"
                    onClick={() => handleClick(homework.homeworkid)}
                    disabled={addedHomeworks[homework.homeworkid]} // Отключаем кнопку, если уже добавлено
                  >
                    {addedHomeworks[homework.homeworkid]
                      ? "Добавлено"
                      : "Добавить"}{" "}
                    {/* Меняем текст кнопки */}
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3}>Нет доступных домашних заданий</td>{" "}
              {/* Сообщение при пустом массиве */}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default CoachSideBar;
