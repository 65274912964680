import React, { useState } from "react";
import "../../Styles/AddHomeworkModal.css";
import { CopyHomework } from "../../Utils/API/Homework";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// Создаем компонент Alert с использованием React.forwardRef
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddHomeworkModal = ({ onClose, owner, homework, action }) => {
  // Состояние для уведомлений
  const [notification, setNotification] = useState(null);

  // Функция для закрытия уведомления
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification(null);
  };

  return (
    <div className="add-modal">
      <div className="modal-content">
        <h2>Добавить домашнюю работу</h2>
        <button
          onClick={async () => {
            try {
              const response = await CopyHomework(owner, homework);
              console.log("Результат добавления дз", response);

              setNotification({
                message: response.data.message,
                severity: "success", // "success", "error", "warning", "info"
              });
            } catch (error) {
              console.error("Ошибка при добавлении дз:", error);

              setNotification({
                message: "Ошибка при добавлении домашней работы.",
                severity: "error",
              });
            }
          }}
        >
          Добавить
        </button>
      </div>

      {/* Уведомление */}
      {notification && (
        <Snackbar
          open={Boolean(notification)}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={notification.severity}
            sx={{ width: "100%" }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default AddHomeworkModal;
