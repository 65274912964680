// HomeworkGrid.jsx

import React, { useEffect, useState, useContext } from "react";
import { Chessboard } from "react-chessboard";
import { useNavigate, useParams } from "react-router-dom";
import "../Styles/HomeworkGrid.css";
import { GetHomeworkGrid } from "../Utils/API/Homework";
import { HomeworkStat } from "../Utils/API/statistic";
import { UserContext } from "./UserContext";
import Notification from "./Modals/Notification";
import AddHomeworkModal from "./Modals/AddHomeworkModal";
import { Grid, Card, CardContent, Typography, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import { HomeworkResults } from "../Utils/API/Homework";
import AssignmentIcon from "@mui/icons-material/Assignment";

const HomeworkGrid = () => {
  const { user } = useContext(UserContext); // Информация о пользователе
  const [puzzles, setPuzzles] = useState([]);
  const [stats, setStats] = useState([]);
  const [aggregateStats, setAggregateStats] = useState(null); // Новое состояние
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();
  const [showAddModal, setShowAddModal] = useState(false); // Состояние модального окна
  const { owner, homework, action, studentid } = useParams();

  const showDetails = !!studentid;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Получение сетки задачек
        const puzzlesResponse = await GetHomeworkGrid(owner, homework);
        setPuzzles(puzzlesResponse.data);
        console.log("Параметры сетки:", owner, homework, action);
        console.log("Пришедшая сетка:", puzzlesResponse.data);

        // Получение статистики домашнего задания
        if (studentid !== undefined) {
          const statsResponse = await HomeworkStat(owner, homework, studentid);
          setStats(statsResponse.data);
          console.log("информация о решении задач:", statsResponse.data);
          const result = await HomeworkResults(owner, homework, studentid);
          // Предполагаем, что statsResponse.data содержит { success: 6, normal: 1, bad: 3, notSolved: 40 }
          console.log("результаты о решении дз", result);
          setAggregateStats(result.data);
        }
      } catch (error) {
        // Обработка ошибок
        if (error.message.includes("Ошибка")) {
          setNotification(error.message);
        } else {
          setNotification("Произошла ошибка. Пожалуйста, попробуйте позже.");
        }
        console.error("Ошибка при загрузке домашнего задания:", error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    if (action === "select") {
      setShowAddModal(true);
    }
  }, [owner, homework, user.id, studentid, action]);

  // Объединение пазлов с их статистикой
  const mergedPuzzles = puzzles.map((puzzle) => {
    const stat = stats.find((s) => s.puzzleid === puzzle.puzzleid);
    return {
      ...puzzle,
      solved: stat ? stat.solvedpuzzleid !== null : false,
      timevalue: stat ? stat.timevalue : null,
      attempt: stat ? stat.attempt : null,
    };
  });
  console.log("результат мерджа", mergedPuzzles);

  // Функция для определения класса попыток
  const getAttemptClass = (attempt) => {
    if (attempt === null || attempt === undefined) {
      return "attempt neutral";
    }
    switch (attempt) {
      case 1:
        return "attempt green";
      case 2:
        return "attempt yellow";
      default:
        return "attempt red";
    }
  };

  // Функция для вычисления процента
  const getPercentage = (count) => {
    if (!aggregateStats) return "0%";
    const totalSolved =
      aggregateStats.success + aggregateStats.normal + aggregateStats.bad;
    const total = totalSolved + aggregateStats.notSolved;
    if (total === 0) return "0%";
    return `${((count / total) * 100).toFixed(1)}%`;
  };

  if (loading) {
    return <div className="loading">Загрузка...</div>;
  }

  return (
    <div className="homework-container">
      <h1 className="homework-title">Домашнее задание: {homework}</h1>

      {aggregateStats && (
        <>
          {/* Карточка Общей Статистики */}
          <Grid container spacing={2} className="stats-grid">
            <Grid item xs={12} sm={6} md={3}>
              <Card className="stat-card total">
                <CardContent>
                  <Typography variant="h6" component="div">
                    Всего задач
                  </Typography>
                  <Tooltip title="Общее количество задач">
                    <AssignmentIcon style={{ fontSize: 30 }} />
                  </Tooltip>
                  <Typography variant="h4">
                    {aggregateStats.puzzleCount}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Остальные карточки статистики */}
            <Grid item xs={12} sm={6} md={3}>
              <Card className="stat-card success">
                <CardContent>
                  <Typography variant="h6" component="div">
                    Успешно
                  </Typography>
                  <Tooltip title="Решены без ошибок">
                    <CheckCircleIcon style={{ fontSize: 30 }} />
                  </Tooltip>
                  <Typography variant="h4">
                    {aggregateStats.success} (
                    {getPercentage(aggregateStats.success)})
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card className="stat-card normal">
                <CardContent>
                  <Typography variant="h6" component="div">
                    Нормально
                  </Typography>
                  <Tooltip title="Решены с небольшими ошибками">
                    <InfoIcon style={{ fontSize: 30 }} />
                  </Tooltip>
                  <Typography variant="h4">
                    {aggregateStats.normal} (
                    {getPercentage(aggregateStats.normal)})
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card className="stat-card bad">
                <CardContent>
                  <Typography variant="h6" component="div">
                    Плохо
                  </Typography>
                  <Tooltip title="Решены с большими ошибками">
                    <WarningIcon style={{ fontSize: 30 }} />
                  </Tooltip>
                  <Typography variant="h4">
                    {aggregateStats.bad} ({getPercentage(aggregateStats.bad)})
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card className="stat-card not-solved">
                <CardContent>
                  <Typography variant="h6" component="div">
                    Не решено
                  </Typography>
                  <Tooltip title="Не решены">
                    <ErrorIcon style={{ fontSize: 30 }} />
                  </Tooltip>
                  <Typography variant="h4">
                    {aggregateStats.notSolved} (
                    {getPercentage(aggregateStats.notSolved)})
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}

      <div className="puzzle-grid">
        {mergedPuzzles.length === 0 ? (
          <div className="no-puzzles">Нет задачек в этой теме</div>
        ) : (
          mergedPuzzles.map((item) => (
            <div
              key={item.puzzleid}
              className="puzzle-item"
              onClick={() => {
                navigate(`/homework/${owner}/${homework}/${item.ordernumber}`);
              }}
            >
              <div className="puzzle-header">
                <div className="puzzle-id">Задачка #{item.ordernumber}</div>
                {showDetails && (
                  <div
                    className="attempt-icon"
                    title={
                      item.attempt !== null && item.attempt !== undefined
                        ? `Попытки: ${item.attempt}`
                        : "Нет данных о попытках"
                    }
                  >
                    <span className={getAttemptClass(item.attempt)}></span>
                  </div>
                )}
              </div>
              <Chessboard
                position={item.fen}
                arePiecesDraggable={false}
                // boardWidth={280}q
              />
              {showDetails && (
                <div className="puzzle-details">
                  <p>
                    <strong>Решена:</strong> {item.solved ? "Да" : "Нет"}
                  </p>
                  <p>
                    <strong>Время:</strong>{" "}
                    {item.timevalue !== null && item.timevalue !== undefined
                      ? ` ${item.timevalue / 1000} сек`
                      : "Нет данных"}
                  </p>
                  <p>
                    <strong>Попытки:</strong>{" "}
                    {item.attempt !== null && item.attempt !== undefined
                      ? item.attempt
                      : "Нет данных"}
                  </p>
                </div>
              )}
            </div>
          ))
        )}
      </div>

      {notification && (
        <Notification
          message={notification}
          onClose={() => setNotification(null)}
        />
      )}
      {showAddModal && (
        <AddHomeworkModal
          onClose={() => setShowAddModal(false)}
          owner={owner}
          homework={homework}
          action={action}
        />
      )}
    </div>
  );
};

export default HomeworkGrid;
