// CoachModal.tsx

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Box,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";

interface Homework {
  id: number;
  theme: string;
  timeSpent: number;
  owner: number;
  studentId: number;
}

const CoachModal: React.FC<{
  isOpen: boolean;
  closeModal: () => void;
  homeworks: Homework[];
}> = ({ isOpen, closeModal, homeworks }) => {
  // Функция для расчёта средней затраченной времени на задания
  const calculateAverageTime = () => {
    const completedHomeworks = homeworks.filter((hw) => hw.timeSpent > 0);
    if (completedHomeworks.length === 0) return 0;
    const totalTime = completedHomeworks.reduce(
      (acc, hw) => acc + hw.timeSpent,
      0
    );
    return Math.round(totalTime / completedHomeworks.length);
  };

  return (
    <Dialog open={isOpen} onClose={closeModal} fullWidth maxWidth="md">
      <DialogTitle>Мои Домашние Задания</DialogTitle>
      <DialogContent>
        {homeworks.length === 0 ? (
          <Typography variant="body1">
            Нет доступных домашних заданий.
          </Typography>
        ) : (
          <Grid container spacing={2}>
            {homeworks.map((hw) => (
              <Grid item xs={12} sm={6} md={4} key={hw.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">{hw.theme}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Время выполнения:{" "}
                      {hw.timeSpent > 0 ? `${hw.timeSpent} мин` : "—"}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      variant="contained"
                      component={Link}
                      to={`/homeworkgrid/${hw.owner}/${encodeURIComponent(
                        hw.theme
                      )}`}
                      startIcon={<VisibilityIcon />}
                      fullWidth
                    >
                      Просмотр
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
        {homeworks.length > 0 && (
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            Среднее время выполнения: {calculateAverageTime()} мин
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CoachModal;
