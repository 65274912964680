import { useContext } from "react";
import * as api from "./API";
import axios from "axios";

const setStatistic = async (
  user,
  puzzleId,
  allMoveInfo,
  timeStart,
  isRigth
) => {
  console.log("user", user);
  const attemptData = {
    Puzzleid: puzzleId,
    Studentid: user.id,
    MoveValue: allMoveInfo.move.san,
    MoveIndex: allMoveInfo.isRigth
      ? allMoveInfo.currentIndex
      : allMoveInfo.currentIndex + 1,
    TimeValue: new Date() - timeStart,
    Isrigth: isRigth,
  };
  console.log("Занесенная в базу стата", attemptData);
  try {
    const response = await axios.post(
      `${api.protocol}://${api.backendHost}/api/statistic/addstat`,
      attemptData
    );
    console.log("Попытка отправлена:", response.data);
  } catch (error) {
    console.error("Ошибка при добавлении попытки:", error);
  }
  console.log("Статистика отправлена");
};



export { setStatistic };

export const HomeworkStat = async (owner, homework, studentid) => {
  try {
    var response = await axios.get(
      `${api.protocol}://${api.backendHost}/api/puzzle/homeworkstatistic/${owner}/${homework}/${studentid}`,
      {
        withCredentials: true, // Разрешаем отправку куки с запросом
      }
    );
    return response;
  } catch (error) {
    // Логируем ошибку для отладки
    console.error("Ошибка при создании домашнего задания:", error);

    // Проверяем тип ошибки и создаем свой объект
    if (error.response) {
      throw new Error(
        `Ошибка ${error.response.status}: ${
          error.response.data.message || "Ошибка на сервере"
        }`
      );
    } else if (error.request) {
      throw new Error("Нет ответа от сервера. Проверьте подключение.");
    } else {
      throw new Error(`Ошибка: ${error.message}`);
    }
  }
};
