// @ts-nocheck

import React, { useState, useEffect, useContext } from "react";
import { Link, redirect } from "react-router-dom";
import styled from "styled-components";
import Cookies from "js-cookie";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";
import * as api from "../Utils/API/API";
import { Logout } from "../Utils/API/Login";
import "../Styles/Header.css";

const Header = () => {
  const { user, setUser } = useContext(UserContext); // информация о юзере
  const navigate = useNavigate();
  const [menuVisible, setMenuVisible] = useState(false);

  const handleLogin = (role) => {
    window.location.href = `${api.protocol}://${api.backendHost}/api/login/redirect/${role}`;
  };

  const toggleMenu = () => {
    if (!menuVisible) {
      axios
        .get(`${api.protocol}://${api.backendHost}/api/profile`, {
          withCredentials: true, // Разрешаем отправку куки с запросом
        })
        .then((response) => {
          console.log("Данные из asp", response.data);
        });
    }
    setMenuVisible((prev) => !prev);
  };

  const handleLogout = async () => {
    Logout();
    setUser((prev) => ({ ...prev, isLogged: false }));
    setMenuVisible(false); // Закрываем меню при выходе
  };

  return (
    <div className="header-container">
      <Link to="/" className="styled-link">
        <div className="logo">Chess dream</div>
      </Link>
      <div style={{ position: "relative" }}>
        <button
          className="button"
          onClick={() => {
            if (user.isLogged && user.role === "student") {
              navigate("/studentprofile");
            } else if (user.isLogged && user.role === "coach") {
              navigate("/coachdashboard");
            } else {
              handleLogin("student");
            }
          }}
        >
          {user.isLogged ? "Профиль" : "Войти"}
        </button>
        {user.isLogged && menuVisible && (
          <div className="dropdown-menu">
            <div>
              <strong>Информация</strong>
              <p>Имя пользователя: {Cookies.get("Lichessname")}</p>
              <p>Токен: {Cookies.get("Lichesstoken")}</p>
            </div>
            <button className="button" onClick={handleLogout}>
              Выйти
            </button>
          </div>
        )}
        {/* <Link to="/puzzle/random/10" className="styled-link">
          <button className="button">Задачи</button>
        </Link> */}
        <Link to="/tactic" className="styled-link">
          <button className="button">Темы</button>
        </Link>
        {!user.isLogged && (
          <button className="button" onClick={() => handleLogin("coach")}>
            Войти как тренер
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
