import axios from "axios";
import * as api from "./API";
import Cookies from "js-cookie";

const addStudent = async (studentData) => {
  try {
    const response = await axios.post(
      `${api.protocol}://${api.backendHost}/api/student/add`,
      studentData
    );
    console.log("Студент успешно добавлен:");
  } catch (error) {
    console.error("Ошибка при добавлении студента:", error);
  }
};

const AspNetLogin = async (studentData) => {
  try {
    const response = await axios.post(
      `${api.protocol}://${api.backendHost}/api/login`,
      studentData,
      {
        withCredentials: true, // Разрешаем отправку куки с запросом
      }
    );
    console.log("Установлены asp куки");
  } catch (error) {
    console.error("Ошибка при добавлении в asp", error);
  }
};

const Logout = async () => {
  // Ваша логика выхода из системы
  Cookies.remove("Lichessname", { path: "" });
  Cookies.remove("Lichesstoken", { path: "" });
  Cookies.remove("role", { path: "" });
  // setLoggedIn(false);
  const response = await axios.get(
    `${api.protocol}://${api.backendHost}/api/logout`,
    {
      withCredentials: true,
      credentials: "include", // Разрешаем отправку куки с запросом
    }
  );
};

export { addStudent, AspNetLogin, Logout };
