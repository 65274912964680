// export const backendHost: string = "chessdream.ru/back";
export const protocol: string | undefined =
  process.env.REACT_APP_BACKEND_PROTOCOL;

export const backendHost: string = process.env.REACT_APP_BACKEND_HOST || "";

// api from asp net

//userInfo

//puzzleInfo

//loginApi

//statisticApi
