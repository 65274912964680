// UserContext.js
import React, { createContext, useState, useEffect } from "react";
import type { ReactNode } from "react"; // Import ReactNode as a type
// @ts-ignore
import Cookies from "js-cookie";
import { GetUserInfo } from "../Utils/API/UserInfo";
import { useLocation } from "react-router-dom";
import { addStudent, AspNetLogin } from "../Utils/API/Login";
import { GetUserId } from "../Utils/API/UserInfo";
import { IUser, Role } from "../Utils/Interfaces/User";

// Создаем UserContext
export const UserContext = createContext<
  | {
      user: IUser;
      setUser: React.Dispatch<React.SetStateAction<IUser>>;
    }
  | undefined
>(undefined);

interface UserProviderProps {
  children: ReactNode;
}

// Провайдер для UserContext
export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<IUser>({
    isLogged: false,
    id: 0,
    role: "anonimous",
  }); // Состояние пользователя

  const location = useLocation(); // Получаем доступ к текущему URL

  useEffect(() => {
    const query = new URLSearchParams(location.search); // Используем location.search для работы с URL
    const token = query.get("token");
    const username = query.get("username");
    const role = query.get("role") as Role;

    if (token !== null && username !== null && role !== null) {
      const studentData = {
        Lichessname: username,
        Lichesstoken: token,
        role: role,
      };

      const updateUser = async () => {
        await addStudent(studentData);
        await AspNetLogin(studentData);
        const id: number = parseInt(await GetUserId());
        setUser({
          isLogged: true,
          role: role,
          id: id,
        });
      };

      updateUser();
    }
  }, [location.search]);

  useEffect(() => {
    const updateUser = async () => {
      try {
        let userInfo = await GetUserInfo();
        console.log("Информация из cookie", userInfo);
        setUser((prev) => ({
          ...prev,
          isLogged: true,
          role: userInfo.role as Role,
          id: parseInt(userInfo.userId), // Теперь здесь будет число
        }));
      } catch {
        console.log("юзер не зареган");
      }
    };
    console.log("Юзер обновлен");
    updateUser();
  }, []);

  useEffect(() => {
    console.log("Информация о юзере", user);
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
