import React from "react";
import HomePage from "./HomePage";
import { Routes, Route, Link } from "react-router-dom";
import Puzzle from "./Puzzle";
import Header from "./Header";
import Tactic from "./Tactic";
import { UserProvider } from "./UserContext";
import CoachDashboard from "./CoashDashboard";
import HomeworkPuzzles from "./HomeworkPuzzles";
import StudentProfile from "./StudentProfile";
import HomeworkGrid from "./HomeworkGrid";
import GenerateHomework from "./GenerateHomework";

const App = () => {
  return (
    <UserProvider>
      <div>
        <header>{<Header />}</header>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/puzzle/:themes/:puzzleId" element={<Puzzle />} />
          <Route
            path="/puzzle/:themes/:puzzleId/:min/:max"
            element={<Puzzle />}
          />
          <Route
            path="/homework/:owner/:homework/:puzzleId"
            element={<HomeworkPuzzles />}
          />
          <Route path="/tactic" element={<Tactic />} />
          <Route path="/coachdashboard" element={<CoachDashboard />} />
          <Route path="/studentprofile" element={<StudentProfile />} />
          <Route
            path="/studentprofile/:studentid"
            element={<StudentProfile />}
          />
          <Route
            path="/homeworkgrid/:owner/:homework"
            element={<HomeworkGrid />}
          />
          <Route
            path="/homeworkgrid/:owner/:homework/action/:action"
            element={<HomeworkGrid />}
          />
          <Route
            path="/homeworkgrid/:owner/:homework/studentid/:studentid"
            element={<HomeworkGrid />}
          />
          <Route path="/generatehomework" element={<GenerateHomework />} />
        </Routes>
      </div>
    </UserProvider>
  );
};

export default App;
