// CoachDashboard.tsx

import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import * as api from "../Utils/API/API";
import { UserContext } from "./UserContext";
import { CreateHomework, GetHomework } from "../Utils/API/Homework";
import CoachModal from "./Modals/CoachModal";
import { Logout } from "../Utils/API/Login";
import { AddTrainee } from "../Utils/API/Coach";
import StudentsModal from "./Modals/StudentModal";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AssignmentIcon from "@mui/icons-material/Assignment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LinkIcon from "@mui/icons-material/Link";
import { useNavigate } from "react-router-dom";

const CoachDashboard: React.FC = () => {
  const [inviteLink, setInviteLink] = useState<string>("");
  const [downloadLink, setDownloadLink] = useState<boolean>(false);
  const [homeworks, setHomeworks] = useState<any[]>([]);
  const [theme, setTheme] = useState<string>("");
  const [lichessName, setLichessName] = useState<string>("");
  const [notification, setNotification] = useState<{
    message: string;
    severity: "success" | "error";
  } | null>(null);
  const [allLinks, setAllLinks] = useState<string[]>([]);
  const [showAllLinks, setShowAllLinks] = useState<boolean>(false);
  const navigate = useNavigate();

  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("Не найден userContext");
  }
  const { user, setUser } = userContext;

  const [isHomeworkModalOpen, setIsHomeworkModalOpen] =
    useState<boolean>(false); // Для управления модальным окном домашних заданий
  const [isStudentsModalOpen, setIsStudentsModalOpen] =
    useState<boolean>(false); // Для управления модальным окном учеников

  // Обработчик выхода из системы
  const handleLogout = async () => {
    try {
      await Logout();
      setUser((prev) => ({ ...prev, isLogged: false, id: 0 }));
      navigate("/");
    } catch (err) {
      setNotification({
        message: "Ошибка при выходе из системы.",
        severity: "error",
      });
      console.error("Ошибка при выходе:", err);
    }
  };

  // Открытие модального окна домашних заданий и загрузка данных
  const openHomeworkModal = async () => {
    try {
      const response = await GetHomework();
      if (response.status === 200) {
        setHomeworks(response.data); // Предполагается, что API возвращает данные в поле data
        setIsHomeworkModalOpen(true);
        console.log("Домашние задания", response.data);
      } else {
        setNotification({ message: response.data.message, severity: "error" });
      }
    } catch (err) {
      setNotification({
        message: "Ошибка при загрузке домашних заданий.",
        severity: "error",
      });
      console.error("Ошибка при загрузке домашних заданий:", err);
    }
  };

  // Закрытие модального окна домашних заданий
  const closeHomeworkModal = () => {
    setIsHomeworkModalOpen(false);
  };

  // Создание домашнего задания
  const handleAddHomework = async (e: React.FormEvent) => {
    e.preventDefault();

    const homeworkData = {
      Theme: theme,
      Owner: user.id,
    };

    try {
      const response = await CreateHomework(homeworkData);
      if (response.status === 200) {
        setNotification({
          message: "Домашнее задание успешно создано.",
          severity: "success",
        });
        setTheme("");
        // Обновляем список домашних заданий, если модальное окно открыто
        if (isHomeworkModalOpen) {
          setHomeworks((prev) => [...prev, response.data]);
        }
      } else {
        setNotification({ message: response.data.message, severity: "error" });
      }
      console.log("Тема создана", response);
    } catch (error: any) {
      if (error.message.includes("Ошибка")) {
        setNotification({ message: error.message, severity: "error" });
      } else {
        setNotification({
          message: "Произошла ошибка. Пожалуйста, попробуйте позже.",
          severity: "error",
        });
      }
      console.error("Ошибка при добавлении домашнего задания:", error.message);
    }
  };

  // Добавление ученика по имени Lichess
  const handleAddTrainee = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!lichessName.trim()) {
      setNotification({
        message: "Имя Lichess не может быть пустым.",
        severity: "error",
      });
      return;
    }

    try {
      const response = await AddTrainee(user.id, lichessName.trim());
      if (response.status === 200) {
        setNotification({
          message: "Ученик успешно добавлен.",
          severity: "success",
        });
        setLichessName("");
        // Можно обновить список учеников здесь, если необходимо
      } else {
        setNotification({ message: response.data.message, severity: "error" });
      }
      console.log("Информация о добавлении студента", response);
    } catch (error: any) {
      setNotification({
        message: "Произошла ошибка при добавлении ученика.",
        severity: "error",
      });
      console.error("Ошибка при добавлении ученика:", error);
    }
  };

  // Генерация ссылки приглашения
  const generateInviteLink = async () => {
    try {
      const response = await axios.get(
        `${api.protocol}://${api.backendHost}/api/generatelink`,
        {
          withCredentials: true, // Разрешаем отправку куки с запросом
        }
      );
      setInviteLink(response.data.link);
      setDownloadLink(true);
      setNotification({
        message: "Ссылка успешно сгенерирована.",
        severity: "success",
      });
    } catch (error) {
      setNotification({
        message: "Ошибка при генерации ссылки.",
        severity: "error",
      });
      console.error("Ошибка при генерации ссылки:", error);
    }
  };

  // Загрузка всех ссылок тренера
  const loadAllLinks = async () => {
    try {
      const response = await axios.get(
        `${api.protocol}://${api.backendHost}/api/coach/coachlink`,
        {
          withCredentials: true,
        }
      );
      console.log("ссылки", response);
      if (response.status === 200) {
        setAllLinks(response.data);
        setShowAllLinks(true);
        setNotification({
          message: "Ссылки успешно загружены.",
          severity: "success",
        });
      } else {
        setNotification({
          message: "Ошибка при загрузке ссылок.",
          severity: "error",
        });
      }
    } catch (error) {
      setNotification({
        message: "Произошла ошибка при загрузке ссылок.",
        severity: "error",
      });
      console.error("Ошибка при загрузке ссылок:", error);
    }
  };

  useEffect(() => {
    if (inviteLink !== "") {
      setDownloadLink(true);
    }
  }, [inviteLink]);

  // Функция для расчёта средней затраченной времени на задания
  const calculateAverageTime = () => {
    const completedHomeworks = homeworks.filter(
      (hw) => hw.completed && hw.timeSpent > 0
    );
    if (completedHomeworks.length === 0) return 0;
    const totalTime = completedHomeworks.reduce(
      (acc, hw) => acc + hw.timeSpent,
      0
    );
    return Math.round(totalTime / completedHomeworks.length);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h3" align="center" gutterBottom>
        Личный Кабинет Тренера
      </Typography>

      <Grid container spacing={3}>
        {/* Добавить Домашнее Задание */}
        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ p: 2, height: "100%" }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Добавить Домашнее Задание
              </Typography>
              <form onSubmit={handleAddHomework}>
                <TextField
                  label="Тема"
                  variant="outlined"
                  fullWidth
                  value={theme}
                  onChange={(e) => setTheme(e.target.value)}
                  required
                  sx={{ mb: 2 }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  fullWidth
                >
                  Добавить
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>

        {/* Генерация Ссылки Приглашения */}
        <Grid item xs={12} md={6} lg={4}>
          <Card
            sx={{
              p: 2,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Пригласить Ученика
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<PersonAddIcon />}
                onClick={generateInviteLink}
                fullWidth
                sx={{ mb: 2 }}
              >
                Сгенерировать Ссылку
              </Button>
              {downloadLink && (
                <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
                  Ссылка для приглашения: <a href={inviteLink}>{inviteLink}</a>
                </Typography>
              )}
              <Button
                variant="outlined"
                color="primary"
                startIcon={<LinkIcon />}
                onClick={loadAllLinks}
                fullWidth
                sx={{ mt: 2 }}
              >
                Показать все ссылки
              </Button>
              {showAllLinks && (
                <List sx={{ mt: 2, maxHeight: 200, overflowY: "auto" }}>
                  {allLinks.map((link, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={<a href={link}>{link}</a>} />
                    </ListItem>
                  ))}
                </List>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Добавить Ученика */}
        <Grid item xs={12} md={6} lg={4}>
          <Card sx={{ p: 2, height: "100%" }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Добавить Ученика
              </Typography>
              <form onSubmit={handleAddTrainee}>
                <TextField
                  label="Имя Lichess"
                  variant="outlined"
                  fullWidth
                  value={lichessName}
                  onChange={(e) => setLichessName(e.target.value)}
                  required
                  sx={{ mb: 2 }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<PersonAddIcon />}
                  fullWidth
                >
                  Добавить
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>

        {/* Просмотр Домашних Заданий */}
        <Grid item xs={12} md={6} lg={4}>
          <Card
            sx={{
              p: 2,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Мои Домашние Задания
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AssignmentIcon />}
                onClick={openHomeworkModal}
                fullWidth
              >
                Просмотреть
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Card
            sx={{
              p: 2,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Составить домашнее задание
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AssignmentIcon />}
                onClick={() => {
                  navigate("/generatehomework");
                }}
                fullWidth
              >
                Просмотреть
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Просмотр Учениками */}
        <Grid item xs={12} md={6} lg={4}>
          <Card
            sx={{
              p: 2,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Мои Ученики
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<VisibilityIcon />}
                onClick={() => setIsStudentsModalOpen(true)}
                fullWidth
              >
                Показать Ученики
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Уведомления */}
      {notification && (
        <Snackbar
          open={Boolean(notification)}
          autoHideDuration={6000}
          onClose={() => setNotification(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => setNotification(null)}
            severity={notification.severity}
            sx={{ width: "100%" }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      )}

      {/* Модальное Окно Домашних Заданий */}
      <CoachModal
        isOpen={isHomeworkModalOpen}
        closeModal={closeHomeworkModal}
        homeworks={homeworks}
      />

      {/* Модальное Окно Ученики */}
      {isStudentsModalOpen && (
        <StudentsModal onClose={() => setIsStudentsModalOpen(false)} />
      )}

      {/* Кнопка Выйти */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Button
          variant="outlined"
          color="error"
          startIcon={<ExitToAppIcon />}
          onClick={handleLogout}
        >
          Выйти
        </Button>
      </Box>
    </Container>
  );
};

export default CoachDashboard;
