// StudentsModal.tsx
// @ts-nocheck

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Tooltip,
  Badge,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { AllTrainee } from "../../Utils/API/Coach";
import { GetHomework, AssignHomework } from "../../Utils/API/Homework";
import { useNavigate } from "react-router-dom";

interface Student {
  id: number;
  name: string;
  email: string;
  lichessName: string;
}

interface Homework {
  id: number;
  theme: string;
  deadline: string;
  completed: boolean;
  timeSpent: number;
}

const StudentsModal: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const [students, setStudents] = useState<Student[]>([]);
  const [homeworks, setHomeworks] = useState<Homework[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showAssignmentsModal, setShowAssignmentsModal] =
    useState<boolean>(false);
  const [currentStudent, setCurrentStudent] = useState<Student | null>(null);
  const [notification, setNotification] = useState<{
    message: string;
    severity: "success" | "error";
  } | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const traineeResponse = await AllTrainee();
        const homeworkResponse = await GetHomework();
        console.log("Все ученики", traineeResponse);
        if (traineeResponse.status === 200 && homeworkResponse.status === 200) {
          setStudents(traineeResponse.data);
          setHomeworks(homeworkResponse.data);
        } else {
          if (traineeResponse.status === 500) {
            setNotification({
              message: traineeResponse.data.message,
              severity: "error",
            });
          }
          if (homeworkResponse.status === 500) {
            setNotification({
              message: homeworkResponse.data.message,
              severity: "error",
            });
          }
        }
      } catch (error) {
        setNotification({
          message: "Произошла ошибка при загрузке данных.",
          severity: "error",
        });
        console.error("Ошибка при загрузке данных:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("Все ученики", students);
    console.log("Все дз", homeworks);
  }, [students, homeworks]);

  const handleAddAssignment = async (assignment) => {
    if (!currentStudent) return;
    try {
      const response = await AssignHomework(currentStudent, assignment.theme);
      console.log(
        "Информация о задании",
        assignment.theme,
        currentStudent.name,
        response.data.message
      );
      setShowAssignmentsModal(false); // Закрыть модальное окно с заданиями
      setNotification({ message: response.data.message, severity: "success" });
    } catch (error: any) {
      setNotification({
        message: "Произошла ошибка при назначении домашнего задания.",
        severity: "error",
      });
      console.error("Ошибка при назначении домашнего задания:", error);
    }
  };

  if (loading) {
    return (
      <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Список учеников</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography variant="body1" sx={{ ml: 2 }}>
            Загрузка...
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      {/* Основное модальное окно с учениками */}
      <Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>Список учеников</DialogTitle>
        <DialogContent>
          {students.length === 0 ? (
            <Typography variant="body1">Нет учеников.</Typography>
          ) : (
            <Grid container spacing={2}>
              {students.map((student, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card>
                    <CardContent>
                      <Typography
                        variant="h6"
                        component="button"
                        onClick={() => {
                          navigate(`/studentprofile/${student.studentId}`);
                        }}
                        sx={{
                          background: "none",
                          border: "none",
                          padding: 0,
                          margin: 0,
                          textAlign: "left",
                          cursor: "pointer",
                          color: "primary.main",
                          textDecoration: "underline",
                          fontSize: "1rem",
                        }}
                      >
                        {String(student.studentName)}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setShowAssignmentsModal(true);
                          setCurrentStudent(student.studentName);
                        }}
                        fullWidth
                        startIcon={<AssignmentIcon />}
                      >
                        Задать домашнее задание
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>

      {/* Модальное окно назначения домашнего задания */}
      {currentStudent && (
        <Dialog
          open={showAssignmentsModal}
          onClose={() => setShowAssignmentsModal(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            Выберите домашнее задание для{" "}
            {currentStudent ? currentStudent.name : ""}
          </DialogTitle>
          <DialogContent>
            {homeworks.length === 0 ? (
              <Typography variant="body1">
                Нет доступных домашних заданий.
              </Typography>
            ) : (
              <Grid container spacing={2}>
                {homeworks.map((assignment) => (
                  <Grid item xs={12} sm={6} key={assignment.id}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">{assignment.theme}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          Дедлайн:{" "}
                          {new Date(assignment.deadline).toLocaleDateString()}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Статус:{" "}
                          {assignment.completed ? "Выполнено" : "Не выполнено"}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Время выполнения:{" "}
                          {assignment.timeSpent > 0
                            ? `${assignment.timeSpent} мин`
                            : "—"}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => handleAddAssignment(assignment)}
                          fullWidth
                          startIcon={<VisibilityIcon />}
                        >
                          Назначить
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowAssignmentsModal(false)}
              color="primary"
            >
              Закрыть
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Уведомления */}
      {notification && (
        <Snackbar
          open={Boolean(notification)}
          autoHideDuration={6000}
          onClose={() => setNotification(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => setNotification(null)}
            severity={notification.severity}
            sx={{ width: "100%" }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default StudentsModal;
