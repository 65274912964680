// StudentProfile.jsx

import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Snackbar,
  Alert,
  Box,
  Badge,
  Tooltip,
  Modal,
  TextField,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { Logout } from "../Utils/API/Login";
import { UserContext } from "./UserContext";
import Notification from "./Modals/Notification"; // Предполагается, что этот компонент использует MUI Snackbar
import {
  GetStudentHomework,
  GetStudentHomeworkById,
} from "../Utils/API/Homework";
import { addCoachByToken } from "../Utils/API/Coach";
import { GetUserInfo, GetUserInfoById } from "../Utils/API/UserInfo";

const StudentProfile = () => {
  const [homeworks, setHomeworks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const { user, setUser } = useContext(UserContext);
  const [userInfo, setUserInfo] = useState({ lichessname: "" });
  const navigate = useNavigate();
  const { studentid } = useParams();

  // Состояние для модального окна добавления тренера
  const [openModal, setOpenModal] = useState(false);
  const [token, setToken] = useState("");

  const handleLogout = async () => {
    try {
      await Logout();
      setUser((prev) => ({ ...prev, isLogged: false }));
      navigate("/");
    } catch (err) {
      setSnackbarMessage("Ошибка при выходе из системы.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      console.error("Ошибка при выходе:", err);
    }
  };

  useEffect(() => {
    const fetchHomeworks = async () => {
      try {
        console.log("id stud", studentid);
        if (studentid === undefined) {
          const response = await GetStudentHomework(); // Предполагается, что GetStudentHomework возвращает массив домашних заданий
          setHomeworks(response);
          console.log("Задачи ученика", response);
        } else {
          const response = await GetStudentHomeworkById(studentid);
          setHomeworks(response);
          console.log("Задачи ученика", response);
        }
      } catch (err) {
        setError("Ошибка при загрузке данных");
        setSnackbarMessage("Ошибка при загрузке данных.");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        console.error("Ошибка при загрузке данных:", err);
      } finally {
        setLoading(false);
      }
    };
    const fetchUser = async () => {
      try {
        if (studentid === undefined) {
          const response = await GetUserInfo();
          setUserInfo(response);
          console.log(response);
        } else {
          const response = await GetUserInfoById(studentid);
          setUserInfo(response);
          console.log(response);
        }
      } catch (err) {
        setError("Ошибка при загрузке данных о пользователе");
        setSnackbarMessage("Ошибка при загрузке данных о пользователе.");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    };
    fetchHomeworks();
    fetchUser();
  }, [studentid]);

  // Функция для расчёта средней затраченной времени на задания
  const calculateAverageTime = () => {
    const completedHomeworks = homeworks.filter(
      (hw) => hw.completed && hw.timeSpent > 0
    );
    if (completedHomeworks.length === 0) return 0;
    const totalTime = completedHomeworks.reduce(
      (acc, hw) => acc + hw.timeSpent,
      0
    );
    return Math.round(totalTime / completedHomeworks.length);
  };

  // Обработчики для модального окна
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleTokenSubmit = async () => {
    if (token) {
      // Логика проверки и отправки токена на сервер
      console.log("Токен для добавления тренера:", token);
      var response = await addCoachByToken(token);
      setSnackbarMessage("Токен отправлен для проверки");
      console.log(response.data);
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      handleCloseModal();
    } else {
      setSnackbarMessage("Пожалуйста, введите токен.");
      setSnackbarSeverity("warning");
      setOpenSnackbar(true);
    }
  };

  if (loading)
    return (
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 10,
        }}
      >
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Загрузка...
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ExitToAppIcon />}
          sx={{ mt: 4 }}
          onClick={handleLogout}
        >
          Выйти
        </Button>
      </Container>
    );

  if (error)
    return (
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 10,
        }}
      >
        <Alert severity="error">{error}</Alert>
        <Button
          variant="contained"
          color="primary"
          startIcon={<ExitToAppIcon />}
          sx={{ mt: 4 }}
          onClick={handleLogout}
        >
          Выйти
        </Button>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    );

  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Информация о студенте */}
          <Grid item xs={12} md={4} lg={3}>
            <Card sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Avatar
                  alt={userInfo.lichessname}
                  src={user.avatarUrl || "/default-avatar.png"}
                  sx={{ width: 100, height: 100, mb: 2 }}
                />
                <Typography variant="h6">{userInfo.lichessname}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {user.email}
                </Typography>
                <Box sx={{ mt: 2, width: "100%" }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Прогресс выполнения:
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <Box
                        sx={{
                          height: 10,
                          borderRadius: 5,
                          backgroundColor: "#e0e0e0",
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            width: `${user.progress || 0}%`,
                            height: "100%",
                            borderRadius: 5,
                            backgroundColor:
                              (user.progress || 0) > 50
                                ? "success.main"
                                : "warning.main",
                            transition: "width 0.5s",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography variant="body2" color="text.secondary">
                        {`${user.progress || 0}%`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <CardActions sx={{ justifyContent: "center", mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<ExitToAppIcon />}
                  onClick={handleLogout}
                >
                  Выйти
                </Button>
              </CardActions>
              <CardActions sx={{ justifyContent: "center", mt: 2 }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleOpenModal}
                >
                  Добавить тренера
                </Button>
              </CardActions>
            </Card>
          </Grid>

          {/* Домашние задания и статистика */}
          <Grid item xs={12} md={8} lg={9}>
            <Grid container spacing={3}>
              {/* Общая статистика */}
              <Grid item xs={12}>
                <Card sx={{ p: 3, backgroundColor: "#f5f5f5" }}>
                  <Typography variant="h5" gutterBottom>
                    Общая статистика
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="subtitle1">Всего заданий</Typography>
                      <Typography variant="h4">{homeworks.length}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="subtitle1">Выполнено</Typography>
                      <Typography variant="h4" color="success.main">
                        {homeworks.filter((hw) => hw.solved).length}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="subtitle1">Не выполнено</Typography>
                      <Typography variant="h4" color="error.main">
                        {homeworks.filter((hw) => !hw.solved).length}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="subtitle1">
                        Среднее время выполнения
                      </Typography>
                      <Typography variant="h4">
                        {calculateAverageTime()} мин
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              {/* Список домашних заданий */}
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  Домашние задания
                </Typography>
                {homeworks.length === 0 ? (
                  <Typography variant="body1">
                    Нет доступных домашних заданий.
                  </Typography>
                ) : (
                  <Grid container spacing={3}>
                    {homeworks.map((homework) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        key={homework.homeworkid}
                      >
                        <Card
                          sx={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <CardContent>
                            <Typography variant="h6" gutterBottom>
                              {homework.theme}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                mb: 1,
                                justifyContent: "space-between",
                              }}
                            >
                              <AssignmentIcon color="action" sx={{ mr: 1 }} />
                              <Typography variant="body2" sx={{ flex: 1 }}>
                                Статус:{" "}
                                {homework.solved ? (
                                  <Badge
                                    badgeContent="Выполнено"
                                    color="success"
                                    sx={{
                                      "& .MuiBadge-badge": {
                                        fontSize: "0.75rem",
                                      },
                                    }}
                                  >
                                    <Tooltip title="Домашнее задание выполнено">
                                      <CheckCircleIcon color="success" />
                                    </Tooltip>
                                  </Badge>
                                ) : (
                                  <Badge
                                    badgeContent=""
                                    color="error"
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      "& .MuiBadge-badge": {
                                        fontSize: "0.75rem",
                                      },
                                    }}
                                  >
                                    <Tooltip title="Домашнее задание не выполнено">
                                      <HourglassEmptyIcon
                                        color="error"
                                        sx={{ mr: 0.5 }}
                                      />
                                    </Tooltip>
                                    <Typography
                                      variant="body2"
                                      component="span"
                                    >
                                      Не выполнено
                                    </Typography>
                                  </Badge>
                                )}
                              </Typography>
                            </Box>
                            {/* <Typography variant="body2" color="text.secondary">
                              Дедлайн:{" "}
                              {new Date(homework.deadline).toLocaleDateString()}
                            </Typography> */}
                            {/* <Typography variant="body2" color="text.secondary">
                              Время выполнения:{" "}
                              {homework.timeSpent > 0
                                ? `${homework.timeSpent} мин`
                                : "—"}
                            </Typography> */}
                          </CardContent>
                          <CardActions>
                            <Button
                              size="small"
                              variant="contained"
                              component={Link}
                              to={`/homeworkgrid/${
                                homework.owner
                              }/${encodeURIComponent(
                                homework.theme
                              )}/studentid/${
                                studentid !== undefined ? studentid : user.id
                              }`}
                              fullWidth
                              startIcon={<ExitToAppIcon />}
                            >
                              Просмотр
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {/* Модальное окно для добавления тренера */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Добавление тренера
          </Typography>
          <TextField
            fullWidth
            label="Введите токен"
            variant="outlined"
            value={token}
            onChange={(e) => setToken(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleTokenSubmit}
          >
            Отправить
          </Button>
        </Box>
      </Modal>

      {/* Snackbar для уведомлений */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default StudentProfile;
